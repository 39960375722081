var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
        reset: function($event) {
          $event.preventDefault()
          return _vm.onReset.apply(null, arguments)
        }
      }
    },
    [
      _c("LoadingController", {
        attrs: { loading: _vm.loading, error: _vm.error },
        on: { retry: _vm.retry },
        scopedSlots: _vm._u(
          [
            {
              key: "loading",
              fn: function() {
                return [
                  _vm._t("loading", function() {
                    return [_c("AppSubmitting")]
                  })
                ]
              },
              proxy: true
            },
            {
              key: "after-error",
              fn: function(ref) {
                var error = ref.attributes.error
                return [_vm._t("default", null, { error: error })]
              }
            },
            {
              key: "default",
              fn: function() {
                return [
                  _vm.submitted
                    ? _vm._t(
                        "submitted",
                        function() {
                          return [
                            _c(
                              "SuccessAlert",
                              [
                                _vm._t(
                                  "success",
                                  function() {
                                    return [
                                      _vm._v(" Form submitted successfully ")
                                    ]
                                  },
                                  { result: _vm.result }
                                )
                              ],
                              2
                            )
                          ]
                        },
                        { result: _vm.result }
                      )
                    : _vm._e(),
                  !_vm.submitted || !_vm.clearOnSubmit
                    ? _vm._t("default")
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }